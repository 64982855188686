/* eslint-disable */
import * as Types from '../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type AdminIndexQueryVariables = Types.Exact<{
  affiliateStatusActive: Types.Scalars['String']['input'];
  affiliateStatusPending: Types.Scalars['String']['input'];
  paymentStatus: Types.Scalars['String']['input'];
  dateStart: Types.Scalars['String']['input'];
  dateEnd: Types.Scalars['String']['input'];
}>;


export type AdminIndexQuery = { __typename?: 'Query', upcomingPaymentsSum: number, user?: { __typename?: 'Affiliate', id: string } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null, activeAffiliateCount: { __typename?: 'PaginatedAffiliate', meta: { __typename?: 'Meta', total: number } }, pendingAffiliateCount: { __typename?: 'PaginatedAffiliate', meta: { __typename?: 'Meta', total: number } }, activeReferralsCount: { __typename?: 'PaginatedReferral', meta: { __typename?: 'Meta', total: number } }, rangedSubscriptions: { __typename?: 'PaginatedReferral', nodes: Array<{ __typename?: 'Referral', id: string, created: string }>, meta: { __typename?: 'Meta', total: number } } };


export const AdminIndexDocument = gql`
    query AdminIndex($affiliateStatusActive: String!, $affiliateStatusPending: String!, $paymentStatus: String!, $dateStart: String!, $dateEnd: String!) {
  user {
    id
  }
  activeAffiliateCount: affiliates(
    page: {limit: 0}
    filter: [{where: {col: "status", op: "=", expr: $affiliateStatusActive}}]
  ) {
    meta {
      total
    }
  }
  pendingAffiliateCount: affiliates(
    page: {limit: 0}
    filter: [{where: {col: "status", op: "=", expr: $affiliateStatusPending}}]
  ) {
    meta {
      total
    }
  }
  activeReferralsCount: referrals(
    page: {limit: 0}
    filter: [{where: {col: "isActive", op: "=", expr: "1"}}, {where: {col: "type", op: "=", expr: "AFFILIATE"}}]
  ) {
    meta {
      total
    }
  }
  upcomingPaymentsSum: paymentsSum(
    filter: [{where: {col: "AffiliateSettings.paypalEmail", op: "<>", expr: ""}}, {where: {col: "AffiliateSettings.status", op: "=", expr: "ACTIVE"}}, {where: {col: "AffiliateSettings.isPaymentOnHold", op: "=", expr: "false"}}, {where: {col: "Payments.status", op: "=", expr: $paymentStatus}}, {andWhere: {col: "Payments.amount", op: ">=", expr: "5000"}}]
  )
  rangedSubscriptions: referrals(
    filter: [{where: {col: "created", op: ">=", expr: $dateStart}}, {where: {col: "created", op: "<", expr: $dateEnd}}, {where: {col: "type", op: "=", expr: "AFFILIATE"}}]
  ) {
    nodes {
      id
      created
    }
    meta {
      total
    }
  }
}
    `;

/**
 * __useAdminIndexQuery__
 *
 * To run a query within a React component, call `useAdminIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminIndexQuery({
 *   variables: {
 *      affiliateStatusActive: // value for 'affiliateStatusActive'
 *      affiliateStatusPending: // value for 'affiliateStatusPending'
 *      paymentStatus: // value for 'paymentStatus'
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useAdminIndexQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AdminIndexQuery, AdminIndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AdminIndexQuery, AdminIndexQueryVariables>(AdminIndexDocument, options);
      }
export function useAdminIndexLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminIndexQuery, AdminIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AdminIndexQuery, AdminIndexQueryVariables>(AdminIndexDocument, options);
        }
export type AdminIndexQueryHookResult = ReturnType<typeof useAdminIndexQuery>;
export type AdminIndexLazyQueryHookResult = ReturnType<typeof useAdminIndexLazyQuery>;
export type AdminIndexQueryResult = Apollo.QueryResult<AdminIndexQuery, AdminIndexQueryVariables>;