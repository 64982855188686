/* eslint-disable */
import * as Types from '../../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type ReferralsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sort: Array<Types.Sort> | Types.Sort;
}>;


export type ReferralsQuery = { __typename?: 'Query', referrals: { __typename?: 'PaginatedReferral', nodes: Array<{ __typename?: 'Referral', id: string, created: string, isActive: boolean, stripe: { __typename?: 'StripeReferrals', plan: string }, user: { __typename?: 'Affiliate', kaid: string, firstName: string, lastName: string, id: string } | { __typename?: 'BareUser', firstName?: string | null, lastName?: string | null, id: string } | { __typename?: 'Seller', firstName: string, lastName: string, id: string } }>, meta: { __typename?: 'Meta', total: number } } };


export const ReferralsDocument = gql`
    query Referrals($limit: Int!, $offset: Int!, $sort: [Sort!]!) {
  referrals(
    filter: {where: {col: "type", op: "=", expr: "AFFILIATE"}}
    page: {limit: $limit, offset: $offset}
    sort: $sort
  ) {
    nodes {
      id
      created
      isActive
      stripe {
        plan
      }
      user {
        firstName
        lastName
        id
        ... on Affiliate {
          kaid
        }
      }
    }
    meta {
      total
    }
  }
}
    `;

/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReferralsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, options);
      }
export function useReferralsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, options);
        }
export type ReferralsQueryHookResult = ReturnType<typeof useReferralsQuery>;
export type ReferralsLazyQueryHookResult = ReturnType<typeof useReferralsLazyQuery>;
export type ReferralsQueryResult = Apollo.QueryResult<ReferralsQuery, ReferralsQueryVariables>;