import { typography } from '@kinsta/base-style';
import { format, sub } from 'date-fns';
import { useState } from 'react';

import {
	Card,
	CardHeader,
	Chart,
	DisplayError,
	Head,
	PageContent,
	PageLoading,
	PageTitle,
} from '@/src/components';
import {
	CommissionsTable,
	ReferralsTable,
} from '@/src/pages/admin/_components';
import * as Styled from '@/src/pages/admin/admin.style';
import { useAdminIndexQuery } from '@/src/pages/admin/index.queries.gen';
import centsToUSD from '@/src/utilities/centsToUSD';
import {
	AffiliateStatus,
	PaymentStatus,
} from '@/src/utilities/graphql-types.gen';
import makeChartData from '@/src/utilities/makeChartData';

const { Headline, Description } = typography;

const Dashboard = () => {
	const [interval] = useState({
		name: 'day',
		dateFormat: 'EEEE',
		start: sub(new Date(), { days: 7 }),
		end: new Date(),
	});
	const { data, loading, error } = useAdminIndexQuery({
		variables: {
			affiliateStatusActive: AffiliateStatus.ACTIVE,
			affiliateStatusPending: AffiliateStatus.PENDING,
			paymentStatus: PaymentStatus.UPCOMING,
			dateStart: format(interval.start, 'yyyy-MM-dd'),
			dateEnd: format(interval.end, 'yyyy-MM-dd'),
		},
	});

	if (error) {
		return <DisplayError variant='page' message={error.message} />;
	}

	if (loading) {
		return <PageLoading />;
	}

	return (
		<>
			<Head title='Dashboard' />

			<PageTitle>Dashboard</PageTitle>
			<PageContent>
				<Styled.Grid>
					<Card isCenteredContent isPadded style={{ gridArea: 'statA' }}>
						<Headline>{data?.activeAffiliateCount?.meta.total}</Headline>
						<Description>Active affiliates</Description>
					</Card>
					<Card isCenteredContent isPadded style={{ gridArea: 'statB' }}>
						<Headline>{data?.activeReferralsCount?.meta.total}</Headline>
						<Description>Active referrals</Description>
					</Card>
					<Card isCenteredContent isPadded style={{ gridArea: 'statC' }}>
						<Headline>{data?.pendingAffiliateCount?.meta.total}</Headline>
						<Description>Awaiting approval</Description>
					</Card>
					<Card isCenteredContent isPadded style={{ gridArea: 'statD' }}>
						<Headline>{centsToUSD(data?.upcomingPaymentsSum ?? 0)}</Headline>
						<Description>Estimated next payment</Description>
					</Card>

					<Card style={{ gridArea: 'subscriptions' }}>
						<CardHeader>Daily referrals</CardHeader>
						<Chart
							data={makeChartData({
								data: data?.rangedSubscriptions?.nodes,
								interval,
							})}
							dateFormat={interval.dateFormat}
							total={data?.rangedSubscriptions?.meta.total}
						/>
					</Card>

					<Card
						style={{
							gridArea: 'referrals',
							display: 'flex',
							flexDirection: 'column',
							alignSelf: 'flex-start',
						}}
					>
						<CardHeader>Latest referrals</CardHeader>
						<ReferralsTable />
					</Card>

					<Card
						style={{
							gridArea: 'commissions',
							alignSelf: 'flex-start',
						}}
					>
						<CardHeader>Latest approved commissions</CardHeader>
						<CommissionsTable />
					</Card>
				</Styled.Grid>
			</PageContent>
		</>
	);
};

export default Dashboard;
