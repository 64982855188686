import { Tag } from '@kinsta/tag';
import Link from 'next/link';
import { useState } from 'react';

import { Button, DateTime, DisplayError, Table } from '@/src/components';
import { useReferralsQuery } from '@/src/pages/admin/_components/ReferralsTable/ReferralsTable.queries.gen';

const ReferralsTable = () => {
	const pageSize = 5;
	const [page, setPage] = useState(0);
	const [sort] = useState([
		{
			column: 'created',
			order: 'desc',
		},
	]);
	const {
		data,
		fetchMore,
		error: referralError,
	} = useReferralsQuery({
		variables: {
			offset: page,
			limit: pageSize,
			sort,
		},
	});

	const referralColumns = [
		{
			title: 'Created On',
			dataIndex: 'created',
		},
		{
			title: 'Affiliate',
			dataIndex: 'affiliate',
		},
		{
			title: 'Plan',
			dataIndex: 'plan',
		},
		{
			title: 'View',
			dataIndex: 'view',
		},
	];

	const referrals = data?.referrals.nodes.map((node) => {
		if (node.user.__typename !== 'Affiliate') {
			return {};
		}

		return {
			...node,
			created: <DateTime timestamp={node.created} />,
			affiliate: (
				<div>
					<p>{`${node.user.firstName} ${node.user.lastName}`}</p>
					<p
						style={{
							width: 120,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{node.user.kaid}
					</p>
				</div>
			),
			plan: node.isActive ? (
				<span style={{ whiteSpace: 'pre' }}>{node.stripe.plan}</span>
			) : (
				<Tag theme='mercury' size='xsmall'>
					Canceled
				</Tag>
			),
			view: (
				<Link href={`referrals/${node.id}`} legacyBehavior passHref>
					<Button type='secondary'>View</Button>
				</Link>
			),
		};
	});

	if (referralError) {
		return <DisplayError variant='page' message={referralError.message} />;
	}

	const handlePageChange = (newPageIndex: number) => {
		setPage(newPageIndex);
		fetchMore({
			variables: {
				offset: newPageIndex,
				limit: pageSize,
			},
		});
	};

	return (
		<Table
			hasTopBorder
			columns={referralColumns}
			dataSource={referrals}
			pageSize={pageSize}
			page={page}
			sort={sort}
			onPageChange={handlePageChange}
			totalCount={data?.referrals?.meta.total}
			emptyMessage={'No records found.'}
		/>
	);
};

export default ReferralsTable;
