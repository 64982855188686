import { capitalize } from 'lodash';
import Link from 'next/link';
import { useState } from 'react';

import { Button, DateTime, Table } from '@/src/components';
import { useLatestCommissionsQuery } from '@/src/pages/admin/_components/CommissionsTable/CommissionsTable.queries.gen';
import centsToUSD from '@/src/utilities/centsToUSD';
import { ServiceType } from '@/src/utilities/graphql-types.gen';

const CommissionsTable = () => {
	const pageSize = 5;
	const [page, setPage] = useState(0);
	const [sort] = useState([
		{
			column: 'created',
			order: 'desc',
		},
	]);
	const { data, fetchMore } = useLatestCommissionsQuery({
		variables: {
			offset: page,
			limit: pageSize,
			sort,
		},
	});

	const formatServiceType = (serviceType: ServiceType) => {
		return {
			[ServiceType.SUBSCRIPTION]: 'Subscription based',
			[ServiceType.PAY_AS_YOU_GO]: 'Pay-as-you-go',
		}[serviceType];
	};

	const commissions = data?.commissions?.nodes?.map((node) => {
		if (node.user.__typename !== 'Affiliate') {
			return {};
		}

		return {
			...node,
			created: <DateTime timestamp={node.created} />,
			affiliate: (
				<div style={{ whiteSpace: 'nowrap' }}>
					<p>
						{node?.user.firstName} {node?.user.lastName}
					</p>
					<p
						style={{
							width: 120,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{node?.user.kaid}
					</p>
				</div>
			),
			amount: centsToUSD(Number(node?.amount)),
			serviceType: formatServiceType(node.serviceType),
			type: capitalize(node?.type.split('_').join(' ')),
			id: (
				<Link
					href={`admin/referrals/${node?.idReferral}`}
					legacyBehavior
					passHref
				>
					<Button type='secondary'>View</Button>
				</Link>
			),
		};
	});

	const handlePageChange = (newPageIndex: number) => {
		setPage(newPageIndex);
		fetchMore({
			variables: {
				offset: newPageIndex,
				limit: pageSize,
			},
		});
	};

	return (
		<Table
			hasTopBorder
			columns={[
				{
					title: 'Approved On',
					dataIndex: 'created',
				},
				{
					title: 'Affiliate',
					dataIndex: 'affiliate',
				},
				{
					title: 'Amount',
					dataIndex: 'amount',
				},
				{
					title: 'Service Type',
					dataIndex: 'serviceType',
				},
				{
					title: 'Type',
					dataIndex: 'type',
				},
				{
					title: 'View',
					dataIndex: 'id',
				},
			]}
			dataSource={commissions}
			totalCount={data?.commissions?.meta.total}
			pageSize={pageSize}
			page={page}
			sort={sort}
			onPageChange={handlePageChange}
			emptyMessage={'No records found.'}
		/>
	);
};

export default CommissionsTable;
